import axios from 'axios';
import { DashboardServiceURL, UserServiceURL, CompanyServiceURL, SociaMediaServiceURL, WebbuilderServiceURL, AdvertisementServiceURL, PaymentServiceURL, CommunicationServiceURL } from './Host';
import { Utility } from "app/utils/Utility";

const userImpressionService = axios.create({
  baseURL: DashboardServiceURL
});

userImpressionService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

const PaymentService = axios.create({
  baseURL: PaymentServiceURL
});

PaymentService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

const communicationService = axios.create({
  baseURL: CommunicationServiceURL
});

communicationService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

const socialNetworkService = axios.create({
  baseURL: SociaMediaServiceURL
});

const userService = axios.create({
  baseURL: UserServiceURL
});

const companyService = axios.create({
  baseURL: CompanyServiceURL
});

companyService.interceptors.request.use(
  async config => {
    (config.headers && ((config.headers.common).Authorization = `Bearer ${Utility.getAuthToken()}`));
    return config;
  },
  error => {
    Promise.reject(error)
  });

const webbuilderService = axios.create({
  baseURL: WebbuilderServiceURL
});

const advertisementService = axios.create({
  baseURL: AdvertisementServiceURL
});


export { userImpressionService, socialNetworkService, userService, companyService, webbuilderService, advertisementService, PaymentService, communicationService };

