import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { authReducer, deviceReducer, appReducer, userDataReducer, impersonationReducer } from './reducer';
import storage from 'redux-persist/lib/storage';
import { getAuth, updateCurrentUser } from 'firebase/auth';
import { Actions } from './Actions';
import { AppState } from '../../model/AppState';
import { DeviceState } from '../../model/DeviceState';
import { AuthState } from '../../model/AuthState';
import { UserImpl } from '@firebase/auth/internal';
// import deploymentHistoryList from "";
// import { loadCompaniesToAppState } from '../services/CompanyService';
import { UserState } from '../../model/UserState';
import { getCurrentLoggedInUser } from '../../services/loginUserServices';
import { ImpersonationState } from 'model/ImpersonationState';

// const latestVersion = deploymentHistoryList.find(u => u.latest)?.version;

const persistedAuthConfig: PersistConfig<AuthState, any, any, any> = {
    key: 'auth',
    storage
}

const persistedUserDataConfig: PersistConfig<UserState, any, any, any> = {
    key: 'userData',
    storage
}

const persistedImpersonationConfig: PersistConfig<ImpersonationState, any, any, any> = {
    key: 'impersonation',
    storage
}

const persistedDeviceConfig: PersistConfig<DeviceState, any, any, any> = {
    key: 'device',
    storage
}

const persistedAppConfig: PersistConfig<AppState, any, any, any> = {
    key: 'app',
    storage,
    blacklist: ['loading', 'notifications', 'notifierConfig']
}

const rootReducer = combineReducers({
    auth:  persistReducer(persistedAuthConfig, authReducer),
    userData:  persistReducer(persistedUserDataConfig, userDataReducer),
    impersonation:  persistReducer(persistedImpersonationConfig, impersonationReducer),
    device: persistReducer(persistedDeviceConfig, deviceReducer),
    app: persistReducer(persistedAppConfig, appReducer)
});

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const persistor = persistStore(store as any, null, async () => {
    // after reloading existed state.
    const state = store.getState();
    if (state.auth.user != null) {
        const auth = getAuth();
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            store.dispatch({type: Actions.SETAUTH, payload: user});
            const systemUser = await getCurrentLoggedInUser(user as any);
            store.dispatch({type: Actions.SETUSERDATA, payload: systemUser});
            unsubscribe();
        });
        const user = state.auth.user;
        const currentUser = UserImpl._fromJSON(auth as any, user as any);
        updateCurrentUser(auth, currentUser);
    }
    // if (state.app.appVersion !== latestVersion) {
    //     store.dispatch({type: Actions.RESETAPPSTATEWITHVERSION, payload: latestVersion});
    // }
    // {
    //     const currentDate = new Date().getHours();
    //     const { lastUpdated, companies } = state.app.companiesData || {lastUpdated: new Date(), companies: []};
    //     if (currentDate - new Date(lastUpdated).getHours() > 23 || companies.length === 0) {
    //         await loadCompaniesToAppState();
    //     }
    // }
});

window.onresize = (event: UIEvent) => {
    const state = store.getState();
    if (window.innerWidth <= 900 && state.device.deviceType === "web") {
        store.dispatch({type: Actions.SETDEVICE, payload: "mobile"})
    }
    if (window.innerWidth > 900 && state.device.deviceType === "mobile") {
        store.dispatch({type: Actions.SETDEVICE, payload: "web"})
    }
}

export { store, persistor }
