import { Actions } from "../redux/Actions";
import { store } from "../redux/store";
import { Notification } from "../../model/Notification";


export class Utility {
    static isUserAuthenticated(): boolean {
        const state = store.getState();
        return state.auth.user != null;
    }

    static setLoading(loading: boolean): void {
        store.dispatch({ type: Actions.SETLOADING, payload: loading });
    }

    static splitIntoSubArray<T>(arr: Array<T>, chunkSize: number): Array<Array<T>> {
        const res: any[] = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    static isInViewport(element: HTMLElement, checkWithInRange: { left: number, top: number, right: number, bottom: number } = { left: 0, right: 0, top: 0, bottom: 0 }) {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= checkWithInRange.top &&
            rect.left >= checkWithInRange.left &&
            rect.bottom <= window.innerHeight - checkWithInRange.bottom &&
            rect.right <= window.innerWidth - checkWithInRange.right
        );
    }

    static getParams(search: string): URLSearchParams {
        return new URLSearchParams(search);
    }

    static getAuthToken() {
        const UserData: string = localStorage.getItem('persist:auth') || '';
        const parseUserData = UserData && JSON.parse(UserData)
        let AUTH_TOKEN: string = "";
        if (parseUserData && parseUserData.user && parseUserData.user != "null") {
            AUTH_TOKEN = JSON.parse(parseUserData.user)?.stsTokenManager.accessToken
        }
        return AUTH_TOKEN;
    };
    // To be used when authentication is implemented
    // static async getRefreshToken() {
    //     let token = await refreshToken();
    //     return token;
    // };

    static getFileNameFromPath(path: string | null): string {
        let filename = "default.jpg";
        try {
            if (path && path.length > 0 && !(path === null || path.match(/^ *$/) !== null)) {
                let lastIndex = path.lastIndexOf('/');
                filename = path.slice(lastIndex + 1)?.trim();
            }
        }
        catch {
            filename = "default.jpg";
        }
        return filename;
    }

    static notify(notification: Notification): void {
        if (notification.identifier === undefined) {
            notification.identifier = Math.random();
        }
        if (notification.type === undefined) {
            notification.type = "info";
        }
        if (notification.closable == undefined || notification.autoHide == undefined) {
            notification.closable = true;
            notification.autoHide = true;
            notification.autoHideDuration = 5000;
        }
        store.dispatch({ type: Actions.SETNOTIFICATION, payload: notification });
        if (notification.autoHide) {
            setTimeout(() => {
                store.dispatch({ type: Actions.UNSETNOTIFICATION, payload: notification.identifier })
            }, notification.autoHideDuration)
        }
    }

    static getChannelShortName(name: string) {
        const words = name.toUpperCase().split(' ');
        return words[0][0] + (words.length > 1 ? words[words.length - 1][0] : "");
    }
}