import React from "react"
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { store } from 'app/redux/store';
import { Actions } from 'app/redux/Actions';
import { refreshToken, signOutUser } from 'services/AuthenticationService';
import jwt_decode from 'jwt-decode';
import finalPropsSelectorFactory from 'react-redux/es/connect/selectorFactory';
import { PathNames } from 'appConfig/PathNames';
import { StockKnocksSiteURL } from 'appConfig/Host';
import { getCurrentLoggedInUser } from '../../services/loginUserServices';
import { CircularProgress } from '@mui/material';
import { ImpersonationUtility } from "app/utils/ImpersonationUtility";
import { Utility } from 'app/utils/Utility';
import { Roles } from "app/utils/constant";
import { RoleUtility } from "app/utils/RoleUtility";

const AuthGuard = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const userData = useSelector((state) => state.userData?.user);
  const impData = useSelector((state) => state.impersonation?.data);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const isValidToken = function (token) {
    const { exp } = jwt_decode(token);
    if (exp > Math.floor(Date.now() / 1000)) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {
    if (searchParams.has('state') === true) {
      let stateData = searchParams.get('state');
      let setStateObj = JSON.parse(stateData)?.authUser;
      store.dispatch({ type: Actions.SETAUTH, payload: setStateObj });
      searchParams.delete('state');
      setSearchParams(searchParams);
      navigate(pathname, { replace: true });
    }
  }, [navigate, pathname, searchParams, setSearchParams]);

  React.useEffect(() => {
    async function refreshImpersonationData() {
      if (userData && RoleUtility.isPremiumCompanyUser(userData.role) === true) {
        if (ImpersonationUtility.isImpersonating(userData?.subscription || null)) {
          await ImpersonationUtility.getImpersonationData(userData.subscription, userData.role).then((data) => {
            if (data && data?.impersonation_enabled) {
              // Utility.notify({
              //   message: `Successfully logged in as ${data.name}`,
              //   type: 'success',
              // });
            }
            store.dispatch({ type: Actions.SETIMPERSONATIONDATA, payload: data });
          }).catch((err) => {
            console.info('refreshImpersonationData Failed.');
            console.error(err);
            Utility.notify({
              message: `Missing Impersonation Data.`,
              type: 'error',
            });
          });
        }
      }
      else {
        store.dispatch({ type: Actions.SETIMPERSONATIONDATA, payload: ImpersonationUtility.NoImpersonation() });
      }
    }
    refreshImpersonationData();
  }, [userData]);

  if (pathname === PathNames.SIGNOUT) {
    return <>{children}</>;
  }

  if (!user || searchParams.has('state') === true) {
    return (<CircularProgress />);
  }

  function validateAndContinue(user) {
    if (user?.stsTokenManager?.accessToken?.length > 0) {
      if (isValidToken(user.stsTokenManager.accessToken)) {
        return <>{children}</>;
      }
      else {
        Utility.notify({
          message: `Token expired. Please signin again`,
          type: 'success',
        });
        return window.location.replace(`${StockKnocksSiteURL}${PathNames.SIGNOUT}?from=${PathNames.DASHBOARD}`);
      }
    }
    else {
      return (<CircularProgress />);
    }
  }

  if (userData) {
    if (RoleUtility.isPremiumCompanyUserPaymentPending(userData.role)) {
      return window.location.replace(`${StockKnocksSiteURL}${PathNames.PLANS}`);
    }
    else if (RoleUtility.isPremiumCompanyUser(userData.role)) {
      if (ImpersonationUtility.isImpersonating(userData.subscription) === impData?.impersonation_enabled === true) {
        return validateAndContinue(user);
      }
      else {
        return (<CircularProgress />);
      }
    }
    else if (RoleUtility.isPremiumUser(userData.role)) {
      return validateAndContinue(user);
    }
    else if (RoleUtility.isAdmin(userData.role)) {
      return validateAndContinue(user);
    }
    else {
      return (<CircularProgress />);
    }
  }
  else {
    getCurrentLoggedInUser(user).then((systemUser) => {
      store.dispatch({ type: Actions.SETUSERDATA, payload: systemUser });
      return (<CircularProgress />);
    }).catch((errCurrUser) => {
      Utility.notify({
        message: `Unable to fetch user details.`,
        type: 'error',
      });
      console.error(errCurrUser);
      return window.location.replace(`${StockKnocksSiteURL}${PathNames.SIGNOUT}?from=${PathNames.DASHBOARD}`);
    });
  }
};

export default AuthGuard;
