// Dashboard Service

const DashboardServiceURL = 'https://dashboard-service-jkk4otkhtq-el.a.run.app/api';
const SociaMediaServiceURL = 'https://socialnetwork-service-jkk4otkhtq-el.a.run.app/api';
const UserServiceURL = 'https://user-service-jkk4otkhtq-el.a.run.app/api';
const WebbuilderServiceURL = 'https://web-builder-service-jkk4otkhtq-el.a.run.app/api';
const AdvertisementServiceURL = 'https://advertisement-service-jkk4otkhtq-el.a.run.app/api';
const StockKnocksSiteURL = 'https://www.stockknocks.com';
const PaymentServiceURL = 'https://payment-service-jkk4otkhtq-el.a.run.app/api';
const CompanyServiceURL = 'https://company-service-jkk4otkhtq-el.a.run.app/api';
const CommunicationServiceURL = 'https://communication-service-jkk4otkhtq-el.a.run.app/api';



export { 
    DashboardServiceURL,
    SociaMediaServiceURL,
    UserServiceURL,
    CompanyServiceURL,
    WebbuilderServiceURL,
    AdvertisementServiceURL,
    StockKnocksSiteURL,
    PaymentServiceURL,
    CommunicationServiceURL
};