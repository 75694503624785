import { AppState } from "../model/AppState";
import { PathNames } from "./PathNames";

const initialAppConfig: AppState = {
    pages: [
        {
            name: PathNames.HOME,
            showSearchBar: false,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: true
        },
        {
            name: PathNames.DASHBOARD,
            showSearchBar: false,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: true
        },
        {
            name: PathNames.SIGNOUT,
            showSearchBar: false,
            showLeftMenu: false,
            enableLeftMenuMinimize: false,
            isMinimized: false,
            showAdvert: false,
            showFooter: true
        },
    ],
    rtlEnabled: false,
    darkModeEnabled: false,
    loading: false,
    appVersion: "1.0.0",
    notifications: [],
    notifierConfig: { position: "topCenter" }
}

export default initialAppConfig;