import React, { useEffect } from 'react';
// import { store } from 'app/redux/store';
import { useDispatch } from "react-redux";
import { Actions } from 'app/redux/Actions';
import { CircularProgress } from '@mui/material';
import { StockKnocksSiteURL } from 'appConfig/Host';
import { PathNames } from 'appConfig/PathNames';

const SignOut = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // store.dispatch({ type: Actions.SETAUTH, payload: null });
    // store.dispatch({ type: Actions.SETUSERDATA, payload: null });
    dispatch({
      type: Actions.CLEARAUTH
    });
    dispatch({
      type: Actions.CLEARUSERDATA
    });
    dispatch({
      type: Actions.CLEARIMPERSONATIONDATA
    });
    return window.location.replace(`${StockKnocksSiteURL}${PathNames.SIGNOUT}?from=${PathNames.DASHBOARD}`);
  }, []);

  return <React.Fragment>
    <CircularProgress />
  </React.Fragment>;
}

export default SignOut;
