export const topBarHeight = 64
export const sideNavWidth = 260
export const navbarHeight = 60
export const sidenavCompactWidth = 80
export const containedLayoutWidth = 1200

export const Roles = {
    "Company_User_Request": "co_prem_request",
    "Company_User": "co_prem_1",
    "Admin": "admin",
    "User": "member",
    "Premium_User_1": "member_prem_1"
};

export const CompanyType = ["Company", "UnlistedCompany"];

export const CompanyTypeNames = {
    "Company": "Company",
    "UnlistedCompany": "UnlistedCompany"
};