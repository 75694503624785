import useSettings from 'app/hooks/useSettings';
import IconButton from '@mui/material/IconButton';

const MatxLogo = ({ className }) => {
  const { settings } = useSettings();
  const theme = settings.themes[settings.activeTheme];

  const mdBrandLogo = <img
  height={43}
  width={94}
  src='/assets/stockKnocks/stockKnocks_Logo.png'
  alt='Stocknocks'
  style={{ marginLeft: "2px" }}
/>

const xsBrandLogo = <img
  height={37}
  width={107}
  src="/assets/stockKnocks/stockKnocks_Logo.png"
  alt='Stocknocks'
  style={{ position:'absolute', left:'-15px' }}
/>

  return (
    <>
      <IconButton disableRipple={true} >{mdBrandLogo} </IconButton>
      {/* <IconButton disableRipple={true}>{xsBrandLogo}</IconButton> */}
      </>
  );
};

export default MatxLogo;
