import { CompanyType, CompanyTypeNames } from "app/utils/constant";
import { companyService } from "../appConfig/AxiosConfig";
import { CompanyModel } from "model/CompanyModel";
import { AxiosResponse } from "axios";

const getCompanyBasics = async (type: string | null | undefined, id: string | null | undefined) :  Promise<CompanyModel | null | undefined> => {
    let response;
    if (id && id.length > 0 && type && type.length > 0 && CompanyType.includes(type)) {
        let tempData: any;
        if (type === CompanyTypeNames.Company) {
            let apiResL: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>(`/unified/getcompanybasics/${type}/co_code/${id}`);
            tempData = apiResL.data;
        }
        else if (type === CompanyTypeNames.UnlistedCompany) {
            let apiResU: AxiosResponse<any> = await companyService.get<any, AxiosResponse<any>>(`/unified/getcompanybasics/${type}/CIN/${id}`);
            tempData = apiResU.data;
        }

        if (tempData) {
            let cData: CompanyModel = {
                id: tempData.id,
                code: tempData.code,
                codeType: tempData.codeType,
                type: type,
                name: tempData.name,
                logo: tempData.logo,
                // logo: `https://storage.googleapis.com/sockknocks-stock-assets/logos/${tempData?.isin?.toUpperCase()}.png` || ''
            };
            response = cData;
        }
        return response;
    }
    else {
        return response;
    }
}

export { getCompanyBasics }