import initialAppConfig from "../../appConfig/initialAppConfig";
import { AppState, PageState } from "../../model/AppState";
import { AuthState } from "../../model/AuthState";
import { UserState } from '../../model/UserState';
import { DeviceState } from "../../model/DeviceState";
import { IReduxAction } from "../../model/ReduxAction"
import { Actions } from "./Actions";
import { ImpersonationState } from "model/ImpersonationState";

function authReducer(state: AuthState = {
    user: null
}, action: IReduxAction): AuthState {
    switch (action.type) {
        case Actions.SETAUTH:
            return { ...state, user: action.payload };
        case Actions.CLEARAUTH:
            return { ...state, user: null };
        default:
            return { ...state };
    }
}

function userDataReducer(state: UserState = {
    lastUpdated: null,
    user: null,
}, action: IReduxAction): UserState {
    switch (action.type) {
        case Actions.SETUSERDATA:
            return { ...state, user: action.payload };
        
        case Actions.CLEARUSERDATA:
            return { ...state, user: null };
        
        default:
            return { ...state };
    }
}

function impersonationReducer(state: ImpersonationState = {
    lastUpdated: null,
    data: null,
}, action: IReduxAction): ImpersonationState {
    switch (action.type) {
        case Actions.SETIMPERSONATIONDATA:
            return { ...state, data: action.payload };

        case Actions.CLEARIMPERSONATIONDATA:
            return { ...state, data: null };

        default:
            return { ...state };
    }
}

function deviceReducer(
    state: DeviceState = { deviceType: window.innerWidth > 900 ? "web" : "mobile" },
    action: IReduxAction): DeviceState {
    switch (action.type) {
        case Actions.SETDEVICE:
            return { ...state, deviceType: action.payload };
        default:
            return { ...state };
    }
}

function appReducer(
    state: AppState = initialAppConfig,
    action: IReduxAction): AppState {
    switch (action.type) {
        case Actions.SETRTL:
            return { ...state, rtlEnabled: action.payload };
        case Actions.SETDARKMODE:
            return { ...state, darkModeEnabled: action.payload };
        case Actions.SETPAGECONFIG:
            const page: PageState = action.payload;
            const currentPersistedPage = state.pages.find(u => u.name == page.name);
            if (currentPersistedPage) {
                currentPersistedPage.enableLeftMenuMinimize = page.enableLeftMenuMinimize;
                currentPersistedPage.isMinimized = page.isMinimized;
                currentPersistedPage.showAdvert = page.showAdvert;
                currentPersistedPage.showLeftMenu = page.showLeftMenu;
                currentPersistedPage.showSearchBar = page.showSearchBar;
            }
            return { ...state, pages: [...state.pages] };
        case Actions.SETLOADING:
            return { ...state, loading: action.payload };
        case Actions.RESETAPPSTATEWITHVERSION:
            return { ...initialAppConfig, appVersion: action.payload };
        case Actions.SETNOTIFICATION:
            return { ...state, notifications: [...state.notifications, action.payload] };
        case Actions.UNSETNOTIFICATION:
            const notifications = state.notifications.filter(u => u.identifier !== action.payload);
            return { ...state, notifications: [...notifications] };
        default:
            return { ...state }
    }
}

export { authReducer, deviceReducer, appReducer, userDataReducer, impersonationReducer };