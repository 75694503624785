import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Seo = Loadable(lazy(() => import('./Seo')));
const Website = Loadable(lazy(() => import('./Website')));
const WebBuilder = Loadable(lazy(() => import('./WebsiteBuilder')));
const LinkPreview = Loadable(lazy(() => import('./linkPreview')));
const Billing = Loadable(lazy(() => import('./Billing')));
const Support = Loadable(lazy(() => import('./Support')));
const Creative = Loadable(lazy(() => import('./UploadCreativeForm')));
const Products = Loadable(lazy(() => import('./Products')));
const Intro = Loadable(lazy(() => import('./Intro')));
const CompanyLogo = Loadable(lazy(() => import('./CompanyLogo')));


const userPanelRoutes = [
    { path: '/user-panel/Seo', element: <Seo /> },
    { path: '/user-panel/Website', element: <Website /> },
    { path: '/user-panel/WebBuilder', element: <WebBuilder /> },
    { path: '/user-panel/Products', element: <Products /> },
    { path: '/user-panel/Intro', element: <Intro /> },
    { path: '/user-panel/CompanyLogo', element: <CompanyLogo /> },
    { path: '/user-panel/Creative', element: <Creative /> },
    { path: '/user-panel/Billing', element: <Billing /> },
    { path: '/user-panel/linkPreview', element: <LinkPreview /> },
    { path: '/user-panel/Support', element: <Support /> },
];

export default userPanelRoutes;