import { AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from "axios";
import { userService } from "../appConfig/AxiosConfig";
import { User } from "../model/User";
// import { userListData } from "../model/UserListData";
import {store} from "../app/redux/store";
import { User as AuthUser} from "firebase/auth";

const getAuthConfig = (): AxiosRequestConfig<any> => {
    const state = store.getState();
    const user = state.auth.user;
    return {
        headers: {
            "Authorization": `bearer ${getSecureInfo(user)}`
        }
    }
}

const getSecureInfo = (user: ({stsTokenManager: {accessToken}} & AuthUser) | any): string => {
    return user?.stsTokenManager?.accessToken;
}

// const getCurrentUser = async (): Promise<userListData> => {
//     let response: AxiosResponse<userListData> = await userService.get<any, AxiosResponse<userListData>>("/user/current", getAuthConfig());
//     return response.data;
// }

const getCurrentLoggedInUser = async (user: AuthUser):  Promise<User> => {
    let response: AxiosResponse<User> = await userService.get<any, AxiosResponse<User>>("/user/current", {
        headers: {
            "Authorization": `bearer ${getSecureInfo(user)}`
        }
    });
    return response.data;
}

export { getCurrentLoggedInUser, getAuthConfig}