import { ImpersonationData } from "model/ImpersonationState";
import { SubscriptionInfo } from "model/User"
import { CompanyType, Roles } from "./constant";
import { CompanyModel } from "model/CompanyModel";
import { getCompanyBasics } from "services/CompanyService";
import { RoleUtility } from "./RoleUtility";

export class ImpersonationUtility {
    static isImpersonating(subscriptionInfo: SubscriptionInfo | null | undefined): boolean {
        return ((subscriptionInfo && subscriptionInfo.impersonation_type?.length > 0 &&
            subscriptionInfo.impersonation_value?.length > 0 &&
            CompanyType.includes(subscriptionInfo.impersonation_type) === true &&
            subscriptionInfo.role?.split(',').includes(Roles.Company_User) === true
        ) === true);
    };
    static async getImpersonationData(subscriptionInfo: SubscriptionInfo | null | undefined, roles: string | null | undefined): Promise<ImpersonationData> {
        let response: ImpersonationData | null | undefined;
        if (RoleUtility.isPremiumCompanyUser(roles)) {
            let cData = await getCompanyBasics(subscriptionInfo?.impersonation_type, subscriptionInfo?.impersonation_value);
            response = this.convertToImpersonationDTO(subscriptionInfo, cData);
        }
        else {
            response = this.NoImpersonation();
        }
        return response;
    };
    static convertToImpersonationDTO(subscriptionInfo: SubscriptionInfo | null | undefined, company: CompanyModel | undefined | null): ImpersonationData {
        if (this.isImpersonating(subscriptionInfo)) {
            let tempData: ImpersonationData = {
                impersonation_enabled: true,
                name: company?.name || 'Company User',
                imp_id: subscriptionInfo?.impersonation_value,
                imp_type: subscriptionInfo?.impersonation_type,
                imp_ref_id: company?.id,
                id: subscriptionInfo?.id,
                coverpic: company?.coverpic || '',
                profilepic: company?.logo || '',
                user_id: subscriptionInfo?.user_id,
            };

            return tempData;
        }
        else {
            return this.NoImpersonation();
        }
    };

    static NoImpersonation(): ImpersonationData {
        let tempData: ImpersonationData = {
            impersonation_enabled: false
        };

        return tempData;
    }
}